import { ActionTypes } from "./types";

import axios from "axios";
import cityTimezones from "city-timezones";
import Pusher from "pusher-js";
import { toast } from "react-toastify";
import Api from "../../api/Api";

import { clearErrors, returnErrors } from "./error-actions";
import { loadItems } from "./items-actions";
import { loadNotifications } from "./notifications-actions";
import { addOrders, loadOrders } from "./orders-actions";

// Check token & load user
export const loadUser = () => (dispatch, getState) => {
  // User loading
  dispatch({ type: ActionTypes.USER_LOADING });

  Api.defaults.headers.common["Authorization"] = "Bearer " + getState().auth.token;
  Api.post("/auth/user")
    .then((res) => {
      dispatch({
        type: ActionTypes.USER_LOADED,
        payload: res.data.user,
      });
      dispatch(loadNotifications(res.data.notifications));

      dispatch(loadItems());
      dispatch(loadOrders());
      dispatch(loadTax());

      // Enable pusher logging - don't include this in production
      Pusher.logToConsole = true;

      let pusher = new Pusher(process.env.REACT_APP_MIX_PUSHER_APP_KEY, {
        broadcaster: "pusher",
        cluster: process.env.REACT_APP_MIX_PUSHER_APP_CLUSTER,
        forceTLS: false,
        auth: {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        },
        authEndpoint: process.env.REACT_APP_SERVER_URL + "/api/broadcasting/auth",
      });

      let channel = pusher.subscribe("orders." + getState().auth.user.id);
      channel.bind("NewOrder", function (data) {
        dispatch(addOrders(data.data));
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response?.data, err.response?.status));
      dispatch({
        type: ActionTypes.AUTH_ERROR,
      });
    });
};

// Check token & load user
export const logout = () => (dispatch, getState) => {
  // User loading
  dispatch({ type: ActionTypes.USER_LOADING });

  Api.defaults.headers.common["Authorization"] = "Bearer " + getState().auth.token;
  Api.post("/auth/logout")
    .then((res) => {
      dispatch({
        type: ActionTypes.LOGOUT_SUCCESS,
      });
      dispatch({
        type: ActionTypes.CLEAR_NOTIFICATIONS,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: ActionTypes.AUTH_ERROR,
      });
    });
};

export const login = (data) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.USER_LOADING });
  axios.get(process.env.REACT_APP_SERVER_URL + "/sanctum/csrf-cookie").then((response) => {
    Api.post("/auth/login", {
      username: data.username,
      password: data.password,
    })
      .then((response) => {
        dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: response.data });
        //dispatch(loadNotifications())
        dispatch(clearErrors());

        dispatch(loadUser());

        // Enable pusher logging - don't include this in production
        // Pusher.logToConsole = true;

        let pusher = new Pusher(process.env.REACT_APP_MIX_PUSHER_APP_KEY, {
          broadcaster: "pusher",
          cluster: process.env.REACT_APP_MIX_PUSHER_APP_CLUSTER,
          forceTLS: false,
          auth: {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          },
          authEndpoint: process.env.REACT_APP_SERVER_URL + "/api/broadcasting/auth",
        });

        let channel = pusher.subscribe("orders." + getState().auth.user.id);
        channel.bind("NewOrder", function (data) {
          dispatch(addOrders(data.data));
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err.response?.data, err.response?.status, "LOGIN_FAIL"));
        dispatch({
          type: ActionTypes.LOGIN_FAIL,
        });
      });
  });
};

export const singup = (data) => (dispatch) => {
  dispatch({ type: ActionTypes.USER_LOADING });
  axios.get(process.env.REACT_APP_SERVER_URL + "/sanctum/csrf-cookie").then((response) => {
    Api.post("/auth/signup", {
      username: data.username,
      email: data.email,
      password: data.password,
      password_confirmation: data.confirm_password,
    })
      .then((response) => {
        dispatch({ type: ActionTypes.REGISTER_SUCCESS, payload: response.data });
        dispatch(clearErrors());
      })
      .catch((err) => {
        dispatch(returnErrors(err.response.data, err.response.status, "REGISTER_FAIL"));
        dispatch({
          type: ActionTypes.REGISTER_FAIL,
        });
      });
  });
};

export const resendEmailVerification = () => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] = "Bearer " + getState().auth.token;
  Api.post("/email/verify/resend")
    .then(() => {
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status, "RESENDEMAILVERIFICATION_FAIL"));
    });
};

export const emailVerification = (verify_url) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] = "Bearer " + getState().auth.token;
  Api.post(verify_url)
    .then(() => {
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status, "VERIFYEMAILVERIFICATION_FAIL"));
    });
};

export const forgotPassword = (data) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.USER_LOADING });
  Api.post("/auth/forgot-password", {
    email: data.email,
  })
    .then(() => {
      dispatch(clearErrors());
      dispatch({ type: ActionTypes.FORGOT_PASSWORD_RESET });
      dispatch({ type: ActionTypes.USER_LOADED_FALSE });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status, "FORGOTPASSWORD_FAIL"));
      dispatch({ type: ActionTypes.FORGOT_PASSWORD_RESET_FALSE });
      dispatch({ type: ActionTypes.USER_LOADED_FALSE });
    });
};

export const forgotPasswordReset = (data) => (dispatch, getState) => {
  console.error("ffff");
  dispatch({ type: ActionTypes.USER_LOADING });
  Api.post("/auth/forgot-password-reset", data)
    .then(() => {
      dispatch(clearErrors());
      dispatch({ type: ActionTypes.FORGOT_PASSWORD_RESET });
      dispatch({ type: ActionTypes.USER_LOADED_FALSE });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status, "FORGOTPASSWORDRESET_FAIL"));
      dispatch({ type: ActionTypes.FORGOT_PASSWORD_RESET_FALSE });
      dispatch({ type: ActionTypes.USER_LOADED_FALSE });
    });
};

export const sendStripeData = (data) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.USER_LOADING });

  let allData = {
    ...data,
    stripe_id: getState()["auth"]["user"]["stripe_id"],
    time_zone: cityTimezones.lookupViaCity(data.business.province).length !== 0 ? cityTimezones.lookupViaCity(data.business.province)[0].timezone : null,
  };

  Api.defaults.headers.common["Authorization"] = "Bearer " + getState().auth.token;
  Api.post("/user/signup/stripe", allData)
    .then((data) => {
      dispatch(clearErrors());
      dispatch(loadUser());
      dispatch({ type: ActionTypes.USER_LOADED_FALSE });
    })
    .catch((err) => {
      dispatch(loadUser());
      dispatch({ type: ActionTypes.USER_LOADED_FALSE });
      dispatch(returnErrors(err.response.data, err.response.status, err.response.data.error_id));
    });
};

export const changePassword = (data) => (dispatch, getState) => {
  let req = Api.post("/user/changepassword", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        return "Password Has Been Updated";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const loadTax = () => (dispatch, getState) => {
  Api.get("/user/profile/tax/get")
    .then((data) => {
      dispatch({
        type: ActionTypes.LOAD_TAXES,
        payload: data.data,
      });
    })
    .catch((err) => {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const addTax = (data) => (dispatch, getState) => {
  let req = Api.post("/user/profile/tax/add", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.ADD_TAX,
          payload: data.data,
        });
        return "tax added successfully";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const updateTax = (data) => (dispatch, getState) => {
  let req = Api.post("/user/profile/tax/update", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.UPDATE_TAX,
          payload: data.data,
        });
        return "tax updated successfully";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const deleteTax = (data) => (dispatch, getState) => {
  let req = Api.post("/user/profile/tax/delete", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.DELETE_TAX,
          payload: data.data,
        });
        return "Tax deleted successfully";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const changeInvoiceSettings = (data) => (dispatch, getState) => {
  let req = Api.post("/user/changeinvoicesettings", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.CHANGE_INVOICE_SETTINGS,
          payload: data.data,
        });
        return "Invoice Settings Has Been Updated";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const changePickupAndDeliverySettings = (data) => (dispatch, getState) => {
  let req = Api.post("/user/changepickupanddeliverysettings", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.CHANGE_PICKUP_AND_DELIVERY_SETTINGS,
          payload: data.data,
        });
        return "Pickup And Delivery Settings Has Been Updated";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};
