import React from "react";

import Api from "../../api/Api";

import Image from "./assets/motorcycle-delivery-4448.svg";

export default function Track(props) {
  React.useEffect(() => {
    Api.get("/track/" + props.match.params.id)
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((err) => {
        console.log("err" + err);
      });
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div style={{ background: "#fff", minHeight: "100vh", textAlign: "center" }}>
      <img width="150" src={Image} className="pt-3 pb-2" />
      <h3>Refresh after a few minutes</h3>
      <h5 style={{ maxWidth: 500, margin: "0 auto", fontSize: 16, lineHeight: 1.6 }}>
        Your order has not been accepted by the restaurant yet. Please try to refresh after a few minutes to track your order
      </h5>
      <button className="btn btn-dark mt-3" onClick={refreshPage}>
        Refresh
      </button>
    </div>
  );
}
