import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import notificationsReducer from "./notificationsReducer";
import cartReducer from "./cartReducer";
import ordersReducer from "./ordersReducer";
import itemsReducer from "./itemsReducer";

const reducers = combineReducers({
  auth: authReducer,
  error: errorReducer,
  notifications: notificationsReducer,
  cart: cartReducer,
  orders: ordersReducer,
  items: itemsReducer,
});

export default reducers;
