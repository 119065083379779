import { ActionTypes } from "../action-creator/types";


const reducer = (state = [], action) => {
    switch (action.type) {
        case ActionTypes.LOAD_NOTIFICATIONS:
            return action.payload
        case ActionTypes.ADD_NOTIFICATIONS:
            return  [action.payload, ...state ]
        case ActionTypes.CLEAR_NOTIFICATIONS:
                return []
        default:
            return state;
    }
}

export default reducer;