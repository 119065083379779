import { ActionTypes } from "../action-creator/types";

const reducer = (state = { items: [], groups: [], categories: [], images: [], loading: false, errors: false }, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_ITEMS:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.LOADING_ITEMS_FALSE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.ERRORS_ITEMS:
      return {
        ...state,
        errors: true,
      };
    case ActionTypes.ERRORS_ITEMS_FALSE:
      return {
        ...state,
        errors: false,
      };
    case ActionTypes.LOAD_ITEMS:
      return {
        ...state,
        // items: action.payload.items.map((item) => ({ ...item, image: action.payload.images.find((x) => x.id == item.image)?.image })),
        items: action.payload.items,
        categories: action.payload.categories,
        groups: action.payload.groups,
        images: action.payload.images,
      };
    case ActionTypes.ADD_ITEM:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case ActionTypes.UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map((item) => (item.id === action.payload.id ? action.payload : item)),
      };
    case ActionTypes.DELETE_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item.id === action.payload),
      };
    case ActionTypes.UPDATE_ITEMS:
      return {
        ...state,
        items: state.items.map((item) => (action.payload.ids.includes(item.item_id) ? { ...item, stock_state: action.payload.stock_state } : item)),
      };
    case ActionTypes.ADD_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, action.payload],
      };

    case ActionTypes.UPDATE_CATEGORY:
      return {
        ...state,
        categories: state.categories.map((category) => (category.id === action.payload.id ? action.payload : category)),
      };

    case ActionTypes.DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter((category) => category.id !== action.payload),
      };

    case ActionTypes.ADD_GROUP:
      return {
        ...state,
        groups: [...state.groups, action.payload],
      };

    case ActionTypes.UPDATE_GROUP:
      return {
        ...state,
        groups: state.groups.map((group) => (group.id === action.payload.id ? action.payload : group)),
      };

    case ActionTypes.DELETE_GROUP:
      return {
        ...state,
        groups: state.groups.filter((group) => group.id !== action.payload),
      };
    default:
      return state;
  }
};

export default reducer;
