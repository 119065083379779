export const ActionTypes = {
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  LOGOUT: "LOGOUT",
  USER_LOADING: "USER_LOADING",
  USER_LOADED: "USER_LOADED",
  USER_LOADED_FALSE: "USER_LOADED_FALSE",
  AUTH_ERROR: "AUTH_ERROR",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAIL: "REGISTER_FAIL",
  GET_ERRORS: "GET_ERRORS",
  CLEAR_ERRORS: "CLEAR_ERRORS",

  CHANGE_INVOICE_SETTINGS: "CHANGE_INVOICE_SETTINGS",

  CHANGE_PICKUP_AND_DELIVERY_SETTINGS: "CHANGE_PICKUP_AND_DELIVERY_SETTINGS",

  RESEND_EMAIL_VERIFICATION: "RESEND_EMAIL_VERIFICATION",

  LOAD_NOTIFICATIONS: "LOAD_NOTIFICATIONS",
  ADD_NOTIFICATIONS: "ADD_NOTIFICATIONS",
  CLEAR_NOTIFICATIONS: "CLEAR_NOTIFICATIONS",

  LOAD_ORDERS: "LOAD_ORDERS",
  ADD_ORDERS: "ADD_ORDERS",
  UPDATE_ORDER: "UPDATE_ORDER",
  UPDATE_ALL_ORDERS: "UPDATE_ALL_ORDERS",
  ACCEPT_DELIVERY: "ACCEPT_DELIVERY",

  LOADING_ITEMS: "LOADING_ITEMS",
  LOADING_ITEMS_FALSE: "LOADING_ITEMS_FALSE",
  ERRORS_ITEMS: "ERRORS_ITEMS",
  ERRORS_ITEMS_FALSE: "ERRORS_ITEMS_FALSE",

  LOAD_ITEMS: "LOAD_ITEMS",
  ADD_ITEM: "ADD_ITEM",
  UPDATE_ITEM: "UPDATE_ITEM",
  DELETE_ITEM: "DELETE_ITEM",
  UPDATE_ITEMS: "UPDATE_ITEMS",

  ADD_CATEGORY: "ADD_CATEGORY",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  DELETE_CATEGORY: "DELETE_CATEGORY",

  ADD_GROUP: "ADD_GROUP",
  UPDATE_GROUP: "UPDATE_GROUP",
  DELETE_GROUP: "DELETE_GROUP",

  LOAD_CART: "LOAD_CART",
  ADD_CART: "ADD_CART",
  REMOVE_CART: "REMOVE_CART",
  UPDATE_CART: "UPDATE_CART",
  CLEAR_CART: "CLEAR_CART",
  CHECKOUT_SUCCEEDED: "CHECKOUT_SUCCEEDED",
  CHECKOUT_SUCCEEDED_FALSE: "CHECKOUT_SUCCEEDED_FALSE",

  FORGOT_PASSWORD_RESET: "FORGOT_PASSWORD_RESET",
  FORGOT_PASSWORD_RESET_FALSE: "FORGOT_PASSWORD_RESET_FALSE",

  ADD_TAX: "ADD_TAX",
  UPDATE_TAX: "UPDATE_TAX",
  LOAD_TAXES: "LOAD_TAXES",
  DELETE_TAX: "DELETE_TAX",
};
