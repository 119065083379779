import { ActionTypes } from "../action-creator/types";

const reducer = (state = { orders: [], groups: [] }, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_ORDERS:
      return action.payload;
    case ActionTypes.ADD_ORDERS:
      return {
        ...state,
        orders: [action.payload, ...state.orders],
      };
    case ActionTypes.UPDATE_ORDER:
      return {
        ...state,
        orders: state.orders.map((order) => (order.order_id == action.payload.order_id ? action.payload : order)),
      };
    case ActionTypes.UPDATE_ALL_ORDERS:
      return {
        ...state,
        orders: state.orders.map((order) => (!action.payload.includes(order.order_id) ? { ...order, status: "Completed" } : order)),
      };
    case ActionTypes.ACCEPT_DELIVERY:
      return {
        ...state,
        orders: state.orders.map((order) => (order.order_id == action.payload.order_id ? { ...order, delivery_id: action.payload.delivery_id } : order)),
      };

    default:
      return state;
  }
};

export default reducer;
