import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

function PublicRoute({ component: Component, authed, ...rest }) {
  const state = useSelector((state) => state.auth.isAuthenticated);

  return <Route {...rest} render={(props) => (state !== true ? <Component {...props} /> : <Redirect to={{ pathname: "/dashboard" }} />)} />;
}

export default PublicRoute;
