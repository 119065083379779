import { ActionTypes } from "./types";

import Api from "../../api/Api";
import { returnErrors } from "./error-actions";

export const loadNotifications = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.LOAD_NOTIFICATIONS,
    payload: data,
  });
};

export const addNotifications = (notification) => (dispatch) =>
  dispatch({
    type: ActionTypes.ADD_NOTIFICATIONS,
    payload: notification,
  });

export const markNotificationsReaded = () => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] = "Bearer " + getState().auth.token;
  Api.post("/user/notifications/read")
    .then((res) => {
      dispatch({
        type: ActionTypes.LOAD_NOTIFICATIONS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status, "NOTIFICATIONS_FAIL"));
    });
};
