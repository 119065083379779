import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import Spinner from "./components/dashboard/shared/Spinner";
import Track from "./components/public/track";


const Home = lazy(() => import("./components/public/Home"));
const Dashboard = lazy(() => import("./components/dashboard/index"));
const Login = lazy(() => import("./components/auth/Login"));
const Signup = lazy(() => import("./components/auth/Signup"));
const EmailVerify = lazy(() => import("./components/dashboard/pages/EmailVerify"));
const ForgotPassword = lazy(() => import("./components/auth/ForgotPassword"));
const ForgotPasswordReset = lazy(() => import("./components/auth/ForgotPasswordReset"));

const index = lazy(() => import("./components/public"));

const AppRoutes = () => {
  const state = useSelector((state) => state.auth.token);

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
      <PublicRoute path="/" component={Home} exact />
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/signup" component={Signup} />
        <PublicRoute path="/forgotpassword" component={ForgotPassword} />
        <PublicRoute path="/forgotpasswordreset" component={ForgotPasswordReset} />

        <Route path="/track/:id" component={Track} />

        <PrivateRoute authed={state} path="/dashboard" component={Dashboard} />

        <Route path="/order/:username" component={index} />

        <Route path="/auth/email/verify" component={EmailVerify} />

        <Redirect to="/login" />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
