import { ActionTypes } from "../action-creator/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  isLoading: false,
  emailHasSent: false,
  user: null,
  taxes: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };
    case ActionTypes.USER_LOADED_FALSE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FORGOT_PASSWORD_RESET:
      return {
        ...state,
        emailHasSent: true,
      };

    case ActionTypes.FORGOT_PASSWORD_RESET_FALSE:
      return {
        ...state,
        emailHasSent: false,
      };
    case ActionTypes.LOGIN_SUCCESS:
    case ActionTypes.REGISTER_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        ...action.payload,
      };
    case ActionTypes.AUTH_ERROR:
    case ActionTypes.LOGIN_FAIL:
    case ActionTypes.REGISTER_FAIL:
    case ActionTypes.LOGOUT_SUCCESS:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isLoading: false,
        user: null,
      };
    case ActionTypes.ADD_TAX:
      return {
        ...state,
        taxes: [...state.taxes, action.payload],
      };
    case ActionTypes.UPDATE_TAX:
      return {
        ...state,
        taxes: state.taxes.map((tax) => (tax.id === action.payload.id ? action.payload : tax)),
      };
    case ActionTypes.DELETE_TAX:
      return {
        ...state,
        taxes: state.taxes.filter((tax) => tax.id !== action.payload),
      };
    case ActionTypes.LOAD_TAXES:
      return {
        ...state,
        taxes: action.payload,
      };
    case ActionTypes.CHANGE_INVOICE_SETTINGS:
      return {
        ...state,
        user: { ...state.user, invoicetext: action.payload },
      };
    case ActionTypes.CHANGE_PICKUP_AND_DELIVERY_SETTINGS:
      return {
        ...state,
        user: { ...state.user, is_pickup_enabled: action.payload.is_pickup_enabled, is_delivery_enabled: action.payload.is_delivery_enabled },
      };
    default:
      return state;
  }
};

export default reducer;
