import { ActionTypes } from "./types";
import { toast } from "react-toastify";
import Api from "../../api/Api";

export const loadItems = () => (dispatch, getState) => {
  Api.get("/menu/item")
    .then((response) => {
      dispatch({
        type: ActionTypes.LOAD_ITEMS,
        payload: response.data,
      });
    })
    .catch((err) => {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const addItem = (data) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.LOADING_ITEMS });
  let req = Api.post("/menu/item/create", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({ type: ActionTypes.LOADING_ITEMS_FALSE });
        dispatch({
          type: ActionTypes.ADD_ITEM,
          payload: data.data,
        });
        return "The Item has been Added";
      },
    },
    error: {
      render({ data }) {
        dispatch({ type: ActionTypes.LOADING_ITEMS_FALSE });
        return data.response.data.message;
      },
    },
  });
};

export const updateItem = (data) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.LOADING_ITEMS });

  let req = Api.post("/menu/item/update", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.UPDATE_ITEM,
          payload: data.data,
        });
        dispatch({ type: ActionTypes.LOADING_ITEMS_FALSE });
        return "The Item has been updated";
      },
    },
    error: {
      render({ data }) {
        dispatch({ type: ActionTypes.LOADING_ITEMS_FALSE });
        return data.response.data.message;
      },
    },
  });
};

export const deleteItem = (data) => (dispatch, getState) => {
  let req = Api.post("/menu/item/delete", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render() {
        dispatch({
          type: ActionTypes.DELETE_ITEM,
          payload: data,
        });
        return "The Item has Been Deleted";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const changeItemStock = (data) => (dispatch, getState) => {
  let req = Api.post("/menu/item/changestock", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.UPDATE_ITEM,
          payload: data.data.data,
        });
        return "The Item Stock Has Been Updated";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const changeAllItemsStock = (data) => (dispatch, getState) => {
  let req = Api.post("/menu/item/changeitemsstock", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render() {
        dispatch({
          type: ActionTypes.UPDATE_ITEMS,
          payload: { ids: data.ids, stock_state: data.stock_state },
        });
        return "The All Items Has Been Updated";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const addCategory = (data) => (dispatch, getState) => {
  let req = Api.post("/menu/category/create", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.ADD_CATEGORY,
          payload: data.data,
        });
        return "The Category has been Added ";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const updateCategory = (data) => (dispatch, getState) => {
  let req = Api.post("/menu/category/update", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.UPDATE_CATEGORY,
          payload: data.data,
        });
        return "The Category has been updated";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const deleteCategory = (data) => (dispatch, getState) => {
  let req = Api.post("/menu/category/delete", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.DELETE_CATEGORY,
          payload: data.data,
        });
        return "The Category has been Deleted";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const addGroup = (data) => (dispatch, getState) => {
  let req = Api.post("/menu/group/create", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.ADD_GROUP,
          payload: data.data,
        });
        return "The Group has been Added ";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const updateGroup = (data) => (dispatch, getState) => {
  let req = Api.post("/menu/group/update", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.UPDATE_GROUP,
          payload: data.data,
        });
        return "The Group has been updated";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const deleteGroup = (data) => (dispatch, getState) => {
  let req = Api.post("/menu/group/delete", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.DELETE_GROUP,
          payload: data.data,
        });
        return "The Group has been Deleted";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};
