import React, { Component } from "react";

import Logo2 from '../../public/assets/images/site order Logo 13.png';

export class Spinner extends Component {
  render() {
    return (
      <div id="preloader">
        <div id="status">
          <div className="logo">
            <img src={Logo2} height={100} className="d-block mx-auto" alt="" />
          </div>
          <div className="sk-chase mt-4">
            <div className="sk-chase-dot" />
            <div className="sk-chase-dot" />
            <div className="sk-chase-dot" />
            <div className="sk-chase-dot" />
            <div className="sk-chase-dot" />
            <div className="sk-chase-dot" />
          </div>
        </div>
      </div>
    );
  }
}

export default Spinner;
